.card {
    display: flex;
    border: 1px solid #2D3748;
    background: #171923;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem 0;

    .card-image {
        width: 10rem;
        height: 10rem;
        margin-right: 1rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .card-content {
        flex: 1;

        h3 {
            margin-top: 0;
            margin-bottom: 0.1em;
            color: #bada55;
        }
    }
}

.powered-by {
    font-size: 0.8em;
    z-index: 1;
    position: relative;
    margin-left: 2em;

    a {
        text-decoration: none;
        opacity: 0.75;
        transition: opacity 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    i::before {
        font-size: 2rem;
        vertical-align: bottom;
    }
}

@media screen and (max-width: 640px) {
    .powered-by {
        display: none;
    }
}