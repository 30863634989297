.project {
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    -moz-box-align: center;
    align-items: center;
    display: grid;
    position: relative;
    margin-bottom: 3em;

    .project-screenshot {
        box-shadow: 0 10px 30px -15px var(--navy-shadow);
        transition: var(--transition);
        grid-area: 1 / 6 / -1 / -1;
        grid-column-start: 6;
        grid-column-end: -1;
        position: relative;
        z-index: 1;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0px;
            z-index: 3;
            background-color: darken(#2C3A4F, 20%);
            mix-blend-mode: screen;
            opacity: 1;

            transition: 0.3s;
        }

        img {
            width: 100%;
            display: block;

            filter: grayscale(50%);
            opacity: 0.8;

            transition: 0.3s;
        }
    }

    &:hover {
        .project-screenshot {
            &:before {
                opacity: 0;
            }

            img {
                filter: grayscale(0%);
                opacity: 1;
            }
        }
    }

    .project-info {
        z-index: 2;

        position: relative;
        grid-area: 1 / 1 / -1 / 7;

        h3 {
            font-size: 1.5rem;
            line-height: 1.3em;
            margin: 0;
        }

        .project-links {
            font-size: 0.9em;
            margin-top: 0.3em;

            a {
                padding: 0.3em;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .project-description {
            background: #2C3A4F;
            box-shadow: 0 10px 30px -15px #1E2939;
            padding: 1rem;
            margin: 0.5em 0;

            p {
                margin: 0;
                font-size: 0.9em;

                &:not(:first-child) {
                    margin-top: 0.3em;
                }
            }
        }

        ul.tags {
            list-style: none;
            padding: 0;
            display: flex;

            li {
                background: darken(#bada55, 20%);
                margin: 0 0.5em 0.3em 0;
                padding: 0.5em;
                border-radius: 3px;
                color: #fff;
                font-size: 0.7em;
                line-height: 1em;
            }
        }
    }

    &:nth-of-type(2n+1) {
        .project-screenshot {
            grid-column: 1 / 8;
        }

        .project-info {
            grid-column: 7 / -1;
            text-align: right;

            ul.tags {
                justify-content: end;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .project {
        .project-info {
            grid-column: 1 / -1 !important;
            padding: 40px 40px 30px;
            text-align: left !important;

            background: rgba(44, 58, 79, 0.95);
            box-shadow: 0 10px 30px -15px #1E2939;

            .project-description {
                background: none;
                box-shadow: none;
                padding: 0;
            }
        }

        .project-screenshot {
            grid-column: 1 / -1 !important;
        }
    }
}