.blob {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 100%;
}

.blob svg {
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    z-index: -1;
}

.blob img {
    width: 200px;
    margin: 50px 100px;
}