body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.75;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 768px) {
    body {
        font-size: 14px;
    }
}

.f-left {
    float: left;
}
.f-right {
    float: right;
}

body {
    background: #19202A;
    color: rgba(255, 255, 255, 0.92);
    overflow-y: scroll;

    font-family: 'Quicksand', sans-serif;
}

h1 {
    line-height: 1em;
    display: flex;
    position: relative;
    white-space: nowrap;
    margin: 0.8em 0 1.2em;

    &:after {
        content: "";
        display: block;
        position: relative;
        top: 0.5em;
        width: 100%;
        height: 1px;
        margin-left: 1.2em;
        background-color: #1E2939;
    }
}

h1, h2, h3 {
    font-weight: 600;

    a {
        text-decoration: none;
    }
}

a {
    color: white;
    text-decoration-color: #bada55;
    text-decoration-thickness: .01em;
    text-underline-offset: 0.12em;

    &:hover {
        color: #bada55;
    }
}

.container {
    max-width: 900px;
    margin: 0 auto;
}

.columns {
    display: flex;
}

.columns .column {
    flex: 1;
    margin-left: 2rem;
}

.columns .column-centre {
    display: flex;
    align-items: center;
    justify-content: center;
}

.columns .column:first-child {
    margin-left: 0;
}

@media screen and (max-width: 950px) {
    .container {
        max-width: 95%;
    }

    .columns {
        flex-direction: column;
    }

    .columns .column {
        margin-left: 0;
    }
}