.nav {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    padding: 1rem 0;
    position: relative;

    .nav-right {
        display: flex;

        align-items: center;
        flex-direction: row;

        > a:not(:first-child) {
            margin-inline-start: 2rem;
        }
    }

    a.nav-item {
        text-decoration: none;

        &.nav-item--active,
        &.nav-item--active:hover {
            color: #bada55;
        }

        img {
            height: 4em;
            width: 4em;
            vertical-align: middle;
        }
    }

    .nav-indicator {
        opacity: 0;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #bada55;
        height: 1.8em;
        transition: transform 0.25s ease-in-out;
        border-radius: 0.3em;
        will-change: transform;
        backface-visibility: hidden;
        width: 100px;
    }
}